@charset "UTF-8";
@import "button.css";

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	/* Gör en css-reset genom att ta bort all standard margins och paddings */
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}

body {
  margin: 0;
  font-size: 1.1em;
  line-height: 1.6;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}





#app {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  max-width: 500px;
  border: 1px solid black;
  margin: 0 auto;
  background-color: #f8f8f8;
  color: #3f3f3f;
  width: 100%;
}


.modal {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 9999;
  justify-content: center;
  align-items: center;
  background-color: #000000de;
}
.modal-content {
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: #fff;
  padding: 5rem 4rem;
  box-shadow: 0 4px 6px 0 hsla(0, 0%, 0%, 0.2);
  position: relative;
}


/* .input-wrapper {
  display: flex;
  justify-content: space-between
} */

.input-wrapper > input {
  margin: 10px auto;
  padding: 8px;
}


.header-wrapper {
  background-color: #AA3838;
  max-height: 30%;
  width: 100%;
  text-align: center;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.3em;
}
.header-wrapper > h1 {
  margin: 1em;
  transform: rotate(-11deg)
}

.startBtn-wrapper {
position: absolute;
bottom: 2em;
}

.player-wrapper {
width: 100%;
padding: 0em 1em;
}
.player-wrapper > h1 {
  text-align: center;
}

.player-wrapper > div {
  margin: 10px 0;
}

.player {
  display: flex;
  justify-content: space-between;
}



td > input {
	width: 100%;
	border: none;
	padding: 14px 0;
	text-align: center;
  margin: -6px 0;
  font-size: 1rem;
  font-weight: 700;
}





@media screen and (max-width: 400px) {
  .modal-content {
    padding: 5rem 3rem;
  }
}

@media screen and (max-width: 330px) {
  .modal-content {
    padding: 5rem 2rem;
  }
}