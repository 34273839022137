.btn {
    padding: 8px 12px;
    border: 1px solid transparent;
    cursor: pointer;
    font-weight: 400;
    border-radius: 4px;
    margin: 0 auto;
}

.btn-success {
    color: #3f3f3f;
    background-color: transparent;
    border-color: #3f3f3f;
    width: 10em;
}
.btn-success:not(:disabled):hover {
    color: #fff;
    border-color: #FFF;
    background-color: #3f3f3f;
}

.btn-primary {
	color: #fff;
	background-color: #337ab7;
	border-color: #2e6da4;
}
.btn-primary:hover {
	color: #fff;
	background-color: #286090;
	border-color: #204d74;
}

.btn-transparent {
    background-color: transparent;
    color: #fff;
    border-color: #fff;
    border-radius: 22px;
    font-weight: 600;
}
.btn-transparent:hover {
    color: #000;
    background-color: #FFF;

}

.btn-danger {
    margin: 0;
    background-color: #FF0000;
    color: #fff;
}

.btn-remove {
    font-weight: 700;
    cursor: pointer;
}

.btn-remove:hover {
    transform: scale(1.3);
}

.btn-add {
    background-color: transparent;
}

.btn-add:hover {
    transform: scale(1.1)
}


button:disabled,
button[disabled]{
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}