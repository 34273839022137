table, td, th {
    border: 1px solid black;
}
  
table {
    border-collapse: collapse;
    width: 100%;
}

td {
    padding: 8px 0;
}

thead > tr > th {
    position: sticky;
    top: 0;
    background-color: #AA3838;
    color: #fbfbfb;
}